













































































































































































































































































































































































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import OtpModal from '@/components/OtpModal.vue'

import Dispatching from '../../../../store/models/Dispatching'
import DispatchingFile from '../../../../store/models/DispatchingFile'
import uiState from '../../../../ui-state'
import { mapGetters } from 'vuex'

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      otpModal: InstanceType<typeof OtpModal>
    }
  }
>).extend({
  components: {
    OtpModal,
    BalanceCard
  },

  data() {
    return {
      items: [],
      hasErrors: false,
      validationFailed: false,
      reports: [],
      mtnAmount: 0,
      moovAmount: 0,
      totalAmount: 0,
      creditLine: null,
      columns: [
        { label: '#', field: '_iteration', sortable: false },
        { label: 'Agence du SFD', mapping: 0, field: 'agency' },
        { label: 'Nom complet', mapping: 1, field: 'name' },
        { label: 'Sexe', mapping: 2, field: 'gender' },

        { label: 'NPI', mapping: 3, field: 'npi' },
        {
          label: 'NOM DU BENEFICIAIRE RAVIP',
          mapping: 4,
          field: 'ravipLastname'
        },
        {
          label: 'PRENOMS DU BENEFICIAIRE RAVIP',
          mapping: 5,
          field: 'ravipFirstname'
        },

        { label: 'Département', mapping: 6, field: 'state' },
        { label: 'Commune', mapping: 7, field: 'commune' },
        { label: 'Arrondissement', mapping: 8, field: 'district' },
        { label: 'Village', mapping: 9, field: 'town' },
        { label: 'Numéro mobile', mapping: 10, field: 'phone' },
        { label: 'Groupe de solidarité', mapping: 11, field: 'group' },
        { label: 'Numéro du dossier', mapping: 12, field: 'reference' },
        { label: 'Poste occupé', mapping: 13, field: 'position' },
        { label: "Secteur d'activité", mapping: 14, field: 'activity' },
        { label: 'Montant du crédit', mapping: 15, field: 'amount' },
        {
          label: 'Montant à rembourser en nominal',
          mapping: 16,
          field: 'refundAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant à rembourser en interêt',
          mapping: 17,
          field: 'interestAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant total à rembourser',
          mapping: 18,
          field: 'totalRefund',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: "Montant de l'échéance",
          mapping: 19,
          field: 'deadlineAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Durée du prêt',
          mapping: 20,
          field: 'loanDuration',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Différé',
          mapping: 21,
          field: 'deferred',
          format: (x: any) => parseInt(x, 10)
        }
      ],
      isMoovAmountInvalid: false,
      isMtnAmountInvalid: false,
      isCreditLineAmountInvalid: false,
      uiState: uiState([
        'idle',
        'fetching_report',
        'report_ready',
        'canceling',
        'validating',
        'validating_otp',
        'error_request_otp',
        'error_no_otp_user',
        'error_already_validated',
        'error_low_credit_line_balance',
        'error_low_ova_balance',
        'error_beneficiary_has_credit',
        'error_fees_balance_insufficient'
      ])
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    nbOfbeneficiariesFormat(): string {
      const nb = this.getCurrentProduct.creditDetails.nbOfBeneficiaryPerGroup
      if (nb.length > 0) return nb.join(', ')
      return ''
    },
    dispatchingId(): string {
      return this.$route.params.dispatchingId
    },

    dispatching(): Dispatching | null {
      return Dispatching.query()
        .withAll()
        .find(this.dispatchingId)
    },

    fetchingReport(): boolean {
      return this.uiState.is('fetching_report')
    },

    reportReady(): boolean {
      return this.uiState.is('report_ready')
    },

    showOtpModal(): boolean {
      return this.uiState.in(['error_request_otp', 'validating_otp'])
    },

    validatingOtp(): boolean {
      return this.uiState.is('validating_otp')
    },

    showNoValidatorsError(): boolean {
      return this.uiState.is('error_no_otp_user')
    },

    showAlreadyValidatedError(): boolean {
      return this.uiState.is('error_already_validated')
    },

    showLowCreditLineBalanceError(): boolean {
      return this.uiState.is('error_low_credit_line_balance')
    },

    showLowOvaBalanceError(): boolean {
      return this.uiState.is('error_low_ova_balance')
    },

    showFeesBalanceInsufficientError(): boolean {
      return this.uiState.is('error_fees_balance_insufficient')
    },

    showBeneficiaryHasCreditError(): boolean {
      return this.uiState.is('error_beneficiary_has_credit')
    }
  },

  created(this: any) {
    this.uiState.set('fetching_report')
    this.getReport(this.dispatchingId)
      .then((response: any) => {
        this.uiState.set('report_ready')
        this.hasErrors = ['failed', 'invalid'].includes(response.reportStep)
        this.validationFailed = response.reportStep === 'failed'
        this.items = response.items
        this.reports = response.reports
        this.mtnAmount = response.mtnAmount
        this.moovAmount = response.moovAmount
        this.totalAmount = response.totalAmount
        this.creditLine = response.creditLine
        this.isMoovAmountInvalid = this.isOtmAmountInvalid('moov')
        this.isMtnAmountInvalid = this.isOtmAmountInvalid('mtn')
        this.isCreditLineAmountInvalid = this.reports.some(
          (r: any) => r.key === 'CT-DTG-04'
        )
      })
      .catch((error: Error) => {
        if (/Network Error/.test(error.message)) {
          this.uiState.set('error_network')
        }
      })
  },

  methods: {
    async getReport(id: string) {
      const dispatching = (await Dispatching.api().getOne(id)).response.data
      const res = await DispatchingFile.api().report(
        dispatching.dispatchingFileId
      )

      return res.response.data
    },

    onValidateDispatchingClicked(otp?: string) {
      if (otp) {
        this.uiState.set('validating_otp')
      } else {
        this.uiState.set('validating')
      }

      Dispatching.api()
        .validate(this.dispatchingId, otp)
        .then(() => {
          this.$router.push({ name: 'dispatchings.index' })
        })
        .catch((error: any) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                if (error.response.data.code === 'no_otp_user') {
                  this.uiState.set('error_no_otp_user')
                } else if (error.response.data.code === 'otp_invalid') {
                  this.$refs.otpModal.setError('Le code otp entré est invalide')
                  this.uiState.set('error_request_otp')
                }
                break
              case 403:
                if (error.response.data.code === 'need_confirmation_otp') {
                  this.uiState.set('error_request_otp')
                } else if (error.response.data.code === 'already_validated') {
                  this.uiState.set(
                    'error_already_validated',
                    error.response.data.validatedBy
                  )
                } else if (error.response.data.code === 'low_balance') {
                  this.uiState.set(
                    'error_low_balance',
                    error.response.data.data
                  )
                } else if (
                  error.response.data.code ===
                  'contains_beneficiary_with_credit'
                ) {
                  this.uiState.set(
                    'error_beneficiary_with_credit',
                    error.response.data.beneficiaries
                  )
                } else if (
                  error.response.data.code === 'has_phone_with_credit'
                ) {
                  this.uiState.set(
                    'error_beneficiary_has_credit',
                    error.response.data.data
                  )
                }
                break
              case 422:
                switch (error.response.data.code) {
                  case 'low_credit_line_balance':
                    this.uiState.set(
                      'error_low_credit_line_balance',
                      error.response.data.data
                    )
                    break
                  case 'low_virtual_account_balance':
                    this.uiState.set(
                      'error_low_ova_balance',
                      error.response.data.data
                    )
                    break
                }
                break
              case 406:
                switch (error.response.data.code) {
                  case 'fees_balance_insufficient':
                    this.uiState.set(
                      'error_fees_balance_insufficient',
                      error.response.data.data
                    )
                    break
                }
            }
          }
        })
    },

    onCancelDispatchingClicked(this: any) {
      this.uiState.set('canceling')
      Dispatching.api()
        .cancel(this.dispatchingId)
        .then(() => {
          this.uiState.set('report_ready')
          this.$router.push({ name: 'dispatchings.index' })
        })
        .catch((error: any) => {
          this.uiState.set('report_ready')
          console.log(error)
        })
    },

    isInvalidWalletAccount(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-02')
      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    isOtmAmountInvalid(this: any, otm: string) {
      const report = this.reports.find((r: any) => r.key === 'CT-DTG-06')

      if (!report || !report.otms) {
        return false
      }

      return report.otms.includes(otm)
    },

    alreadyInDispatching(this: any, index: number) {
      const report = this.reports.find((r: any) => r.key === 'CT-DTG-03')
      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    isPhoneDuplicated(this: any, index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-07')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    isInvalidIdentity(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-01')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    isInvalidProfile(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-09')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    isMoovUnauthorizedAccount(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-23')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    profileCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-10')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    isLineAmountInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-05')
      return report && report.rows[index]
    },

    hasSimilarBeneficiary(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-12')
      return report && report.rows && report.rows[index]
    },

    hasDuplicateName(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-13')
      return report && report.rows && report.rows[index]
    },

    isGroupLengthInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-08')
      return report && report.rows.includes(index)
    },

    hasInvalidDeadlines(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-11')
      return report && report.rows[index]
    },

    loanDurationInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-14')
      return report && report.rows && report.rows[index]
    },

    differedDurationInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-15')
      return report && report.rows && report.rows[index]
    },

    differedGreaterThanLoanDuration(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-17')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    sameLoanDurationForGroup(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-18')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    sameDefferedForGroup(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-19')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-22')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    otherVersionDebtCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-24')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-21')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiInfosInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-20')
      return report && report.rows && report.rows[index]
    },

    moovUnauthorizeNumber(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-23')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    rowClass(item: any, i: number) {
      const hasErrors = this.reports.some((report: any) => {
        if (!report.rows) {
          return false
        }
        return Array.isArray(report.rows)
          ? report.rows.includes(i)
          : Object.keys(report.rows).includes(String(i))
      })
      return hasErrors ? 'bg-red-100' : ''
    },

    activitySectorInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-AS-1')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    genderInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-AS-2')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    stateInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-1')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    communeInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-2')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    communeInStateInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-3')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    districtInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-4')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    villageInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-5')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    positionInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-PS-01')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    presidentPositionInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-PS-02')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    }
  }
})
